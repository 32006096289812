import React, { useState } from 'react';
import { MdModeEdit, MdDeleteOutline } from "react-icons/md";

const CardManager = () => {
  const [cards, setCards] = useState([]);
  const [activeTab, setActiveTab] = useState('cards');

  const addCard = (card) => {
    setCards([...cards, card]);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="js-pg-hg">
      <div className="container">
        <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between mb-4">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Manage Cards</h2>
          </div>
        </div>

        {/* Tab Navigation */}
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === 'cards' ? 'active' : ''}`}
              id="cards-tab"
              onClick={() => handleTabChange('cards')}
            >
              Debit/Credit Card
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === 'paypal' ? 'active' : ''}`}
              id="paypal-tab"
              onClick={() => handleTabChange('paypal')}
            >
              Paypal
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane pt-4 mt-4 fade ${activeTab === 'cards' ? 'show active' : ''}`} id="cards" role="tabpanel">
            {/* Card Form */}
            <div className="card rounded-4">
              <div className="card-header rounded-top-4 border-bottom py-4 bg-white p-4 d-lg-flex d-sm-block justify-content-between">
                <div className='px-3 py-2'>
                  <h5 className="fw-bold card-title">Add New Card</h5>
                  <p className="card-text">Please enter your card details below.</p>
                </div>
              </div>
              <div className="card-body">
                <div className="d-xl-flex d-lg-block d-sm-block flex-row align-items-start justify-content-start">
                  <div className="my-4 px-3 flex-grow-1">
                    <label className="form-label">Name on the Card</label>
                    <input className="form-control" type="text" placeholder="Your name" />
                  </div>
                  <div className="my-4 px-3 w-25 flex-grow-1">
                    <label className="form-label">Card Number </label>
                    <input type="number" className="form-control" placeholder="1235 1452 1478 2589" />
                  </div>
                  <div className="my-4 px-3 flex-grow-1">
                    <label className="form-label">CVV</label>
                    <input className="form-control" type="password" placeholder="Enter your CVV" />
                  </div>
                  <div className="my-4 px-3 flex-grow-1">
                    <label className="form-label">Expiry Date</label>
                    <input className="form-control" type="date" />
                  </div>
                  <div className="mb-4 px-3 mt-5">
                    <button className="btn px-4 py-3 btn-primary">Add Card</button>
                    <button className="btn px-4 ms-4 py-3 btn-outline-primary">Cancel</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Cards List */}
            <div className="row my-5">
              <h3>Cards</h3>
              <div className="table-responsive">
                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Card Number</th>
                      <th>Expiry Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Aravind</td>
                      <td>1235 XXXX XXXX 2589</td>
                      <td>05/29</td>
                      <td className="align-right">
                        <button className="btn float-end fs-5 btn-default btn-link"><MdModeEdit /></button>
                        <button className="btn float-end fs-5 btn-link"><MdDeleteOutline /></button>
                      </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>Aravind</td>
                      <td>2586 XXXX XXXX 2589</td>
                      <td>08/30</td>
                      <td className="align-right">
                        <button className="btn float-end fs-5 btn-default btn-link"><MdModeEdit /></button>
                        <button className="btn float-end fs-5 btn-link"><MdDeleteOutline /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* PayPal Form */}
          <div className={`tab-pane pt-4 mt-4 fade ${activeTab === 'paypal' ? 'show active' : ''}`} id="paypal" role="tabpanel">
            <div className="card rounded-4">
              <div className="card-header rounded-top-4 border-bottom py-4 bg-white p-4 d-lg-flex d-sm-block justify-content-between">
                <div className="px-3 py-2">
                  <h5 className="fw-bold card-title">PayPal Details</h5>
                  <p className="card-text">Please enter your PayPal account information below.</p>
                </div>
              </div>
              <div class="card-body"><div class="my-0 mt-0 px-3"><label class="form-label">PayPal Email</label><input class="form-control" type="email" placeholder="youremail@domain.com" /></div><div class="mb-4 px-3 mt-4"><button class="btn px-4 py-3 btn-primary">Add PayPal Account</button><button class="btn px-4 ms-4 py-3 btn-outline-primary">Cancel</button></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardManager;
