import React, { useState, useEffect } from 'react';
import { MdOutlineInfo } from "react-icons/md";
import { Tooltip } from 'bootstrap';
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';
import DatePickerComponent from '../DatePickerComponent/DatePickerComponent';
import { MdModeEdit } from "react-icons/md";
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);


const Dashboard = () => {


    const datadate = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
          {
            label: 'Resumes Accessed',
            data: [10000, 20000, 30000, 40000, 50000],
            backgroundColor: 'rgba(232, 222, 248, 0.2)',
            borderColor: 'rgba(119, 73, 248, 0.3)',
            borderWidth: 1,
          },
        ],
      };


      const options = {
        responsive: true,
    maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
          },
        },
      };


    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new Tooltip(tooltipTriggerEl);
        });
    }, []);

    const [data, setData] = useState([
        { id: 1, name: 'James', email: 'james@example.com', cv: '8200', accessed: '1800', posted: '200' },
        { id: 2, name: 'Richard', email: 'richard@example.com', cv: '8200', accessed: '1800', posted: '150' },
        { id: 3, name: 'Christopher', email: 'christopher@example.com', cv: '8200', accessed: '1800', posted: '500' },
    ]);

    const handleEdit = (id) => {
        alert(`Edit item with ID: ${id}`);
        // You can add logic to edit the item here
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            setData(data.filter((item) => item.id !== id));
        }
    };

    return (
        <div className="js-pg-hg">
            <div className="container">
                <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between">
                    <div className='js-mn-title me-4 mb-4 mb-lg-0 flex-grow-1'>
                        <h2 className='fw-bold pb-2'>Hey Michael! <span class="badge ms-2 bg-light text-dark">Admin</span></h2>
                        <p>You have 20,000 unassigned resumes/posts out of 500 available.</p>
                    </div>
                    <div className="mt-0 js-select">
                        <div className="form-group mb-4">
                            <label for="New Password" className="form-label">Select Recruiters</label>
                            <AutocompleteDropdown />
                        </div>
                    </div>
                </div>

                <div className="row align-items-center justify-content-center">
                    <div class="col-lg-3">
                        <div class="card p-4 my-4 rounded-5">
                            <div class="card-body">
                                <h5 class="card-title">Resumes Available</h5>
                                <h1 className='fs-1 fw-bold py-2'>22,320</h1>
                                <label class="card-text">40,325 <button className='btn p-0 m-0 text-primary align-bottom' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Total Available"><MdOutlineInfo /></button></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-4 my-4 rounded-5">
                            <div class="card-body">
                                <h5 class="card-title">Resumes Accessed</h5>
                                <h1 className='fs-1 fw-bold py-2'>18,005</h1>
                                <label class="card-text"><button className='btn d-none p-0 m-0 text-primary align-bottom' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Tooltip on top"><MdOutlineInfo /></button></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-4 my-4 rounded-5">
                            <div class="card-body">
                                <h5 class="card-title">Posted Jobs</h5>
                                <h1 className='fs-1 fw-bold py-2'>250</h1>
                                <label class="card-text">750 <button className='btn p-0 m-0 text-primary align-bottom' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Available Job Posting"><MdOutlineInfo /></button></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card p-4 my-4 rounded-5">
                            <div class="card-body">
                                <h5 class="card-title">Unread Messages</h5>
                                <h1 className='fs-1 fw-bold py-2'>5</h1>
                                <label class="card-text">750 <button className='btn p-0 m-0 text-primary align-bottom' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Available Job Posting"><MdOutlineInfo /></button></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-6">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className='js-mn-title flex-grow-1 mt-3'>
                        <h4 className='pb-2'>Recruiters</h4>
                    </div>
                </div>
                <div class="table-responsive">
                        <table className="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email Address</th>
                                    <th scope="col">Resumes Available</th>
                                    <th scope="col">Resumes Accessed</th>
                                    <th scope="col">Job Posted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.cv}</td>
                                        <td>{item.accessed}</td>
                                        <td>{item.posted}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
             <div className="row align-items-center justify-content-center">
             <div class="col-lg-12 mt-4 mb-5 grph">
             <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className='js-mn-title flex-grow-1 mt-3'>
                        <h4 className='pb-2'>Recruiter Analysis</h4>
                    </div>
                    <div className="mt-3">
                        <div className="form-group mb-4">
                            <DatePickerComponent />
                        </div>
                    </div>
                </div>


                <div style={{ position: 'relative', maxWidth: '100%' }}>
            <Bar data={datadate} options={options} height={450} width={null} />
          </div>
            </div>
            </div>
            </div>
        </div>
    );
};

export default Dashboard;