import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CreatePasswordPage from './components/CreatePasswordPage/CreatePasswordPage';
import Dashboard from './components/Dashboard/Dashboard';
import Pricing from './components/Pricing/Pricing';
import LandingPage from './components/LandingPage/LandingPage';
import PricingForm from './components/PricingForm/PricingForm';
import AddRecruiter from './components/AddRecruiter/AddRecruiter';
import ManageRecruiter from './components/ManageRecruiter/ManageRecruiter';
import HeaderLogin from './components/HeaderLogin/HeaderLogin';
import ManagePlan from './components/ManagePlan/ManagePlan';
import ProfileList from './components/ProfileList/ProfileList';
import JobProfileDetail from './components/JobProfileDetail/JobProfileDetail';
import JobPosts from './components/JobPosts/JobPosts';
import EditPostJob from './components/EditPostJob/EditPostJob';
import JobDetail from './components/JobDetail/JobDetail';
import PostJob from './components/PostJob/PostJob';
import AccountSettings from './components/AccountSettings/AccountSettings';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import SavedProfilePg from './components/SavedProfilePg/SavedProfilePg';
import CardManager from './components/CardManager/CardManager';


function App() {
  return (
    <div className="App">

<Router>
      <div className="App">
      <Routes>
      <Route path="/"  element={<><Header /> <LandingPage /></> }  />
      <Route path="/create-password"  element={<><HeaderLogin /><CreatePasswordPage /></>} />
      <Route path="/ManagePlan" element={<><HeaderLogin /> <ManagePlan /></> } />
      <Route path="/dashboard" element={<><HeaderLogin /> <Dashboard /></> } />
      <Route path="/profilelist" element={<><HeaderLogin /> <ProfileList /></> } />
      <Route path="/profile/:id" element={<><HeaderLogin /> <JobProfileDetail /> </>} />
      <Route path="/pricing" element={<><HeaderLogin /> <Pricing /></> } />
      <Route path="/pricingform" element={<><HeaderLogin /> <PricingForm /></> } />
      <Route path="/addrecruiter" element={<><HeaderLogin /> <AddRecruiter /></> } />
      <Route path="/managerecruiter" element={<><HeaderLogin /> <ManageRecruiter /></> } />
      <Route path="/manageplan" element={<><HeaderLogin /> <ManagePlan /></> } />
      <Route path="/jobposts" element={<><HeaderLogin /> <JobPosts /></> } />
      <Route path="/editjobpost" element={<><HeaderLogin /> <EditPostJob /></> } />
      <Route path="/accoutsettings" element={<><HeaderLogin /> <AccountSettings /></> } />
      <Route path="/postjob" element={<><HeaderLogin /> <PostJob /></> } />
      <Route path="/job/:id" element={<><HeaderLogin /> <JobDetail /> </>} />
      <Route path="/forgot-password"  element={<><HeaderLogin /><ForgotPassword /></>} />
      <Route path="/savedprofilepg"  element={<><HeaderLogin /><SavedProfilePg /></>} />
      <Route path="/managecards"  element={<><HeaderLogin /><CardManager /></>} />


      </Routes>
      </div>
    </Router>
    <Footer />

    </div>
  );
}


export default App;
