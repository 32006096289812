import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { MdBookmarkBorder } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { IoMdAlarm } from "react-icons/io";
import { jobProfiles } from '../JobProfiles/JobProfiles';
import RelatedProfile from '../RelatedProfile/RelatedProfile';
import { AiOutlineMail } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";



function JobProfileDetail() {
  const { id } = useParams();
  const profile = jobProfiles.find((p) => p.id === parseInt(id, 10)); // Directly use the jobProfiles array

  if (!profile) return <div>Profile not found.</div>;

  return (

    <div className="js-pg-hg">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" className='text-primary text-decoration-none'>Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/profilelist" className='text-primary text-decoration-none'>Job Profiles</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {profile.name}
            </li>
          </ol>
        </nav>
        <div className="card rounded-4 mb-4 px-4">
          <div className="d-lg-flex d-sm-block py-4 py-sm-0 py-md-0 py-lg-0 align-items-center justify-content-between">
            <div className="py-lg-3 py-sm-4">
              <button className="btn fs-4 p-0 m-0 icon-clr"><MdBookmarkBorder /></button>
              <button className="btn ms-3 small px-3 py-2 btn-outline-primary">Comment       <span className='badge rounded-pill bg-primary small m-0 w ms-2 text-white'>2</span></button>
            </div>
            <div className="py-lg-3 pt-3">
              <p className="card-text">
                <label className="small">Seen</label> : 5 days ago
                <button className="btn fs-4 ms-3 p-0 m-0 icon-clr"><CiMail /></button>
                <button className="btn fs-4 ms-3 p-0 m-0 icon-clr"><IoMdShare /></button>
                <button className="btn fs-3 ms-3 p-0 m-0 icon-clr"><IoMdAlarm /></button>
              </p>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 col-lg-12 js-profile col-xl-8'>
            <div className="card rounded-4 mb-4">
              <div className="d-lg-flex d-sm-block js-pst align-items-stretch p-4">
                <div className="flex-grow-1">
                  <h5 className="card-title">{profile.name}</h5>
                  <span className="card-text">000{profile.id}</span>
                  <div className="d-lg-flex d-sm-block">
                    <div className="card-text mt-3 me-3">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text mt-3 me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>
                    <div className="card-text mt-3">
                      <CiLocationOn className="me-2" /> {profile.location}
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
                <div>
                  <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '100px' }} />
                </div>
              </div>
              <div className="d-lg-flex d-sm-block justify-content-between mx-4 border-top mt-2">
                <div className="py-3">
                  <label className="small">Preferred Locations</label>
                  <p className="card-text pt-2">New York, Texas, Chicago, Remote</p>
                </div>
                <div className="py-3">
                  <label className="small">Notice Period</label>
                  <p className="card-text pt-2">Less than 15 days</p>
                </div>
                <div className="py-3">
                  <p className="card-text pt-2">CV Updated 3 days ago</p>
                </div>
              </div>
            </div>


            <div className="card rounded-4 mb-4 px-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="py-3">
                  <a href='' className="btn btn-link text-decoration-none p-0 m-0 me-4 icon-clr"><AiOutlineMail /> <span className='card-text ms-2'>+1 124 587 5889</span></a>
                  <a href='' className="btn p-0 m-0 icon-clr"><FaPhoneAlt /> <span className='card-text ms-2'>edward@gmail.com</span></a>
                </div>
                <div className="py-3">
                  <p className="card-text">
                    <button className="btn card-text text-decoration-none ms-3 p-0 m-0 btn-link">Hide</button>
                  </p>
                </div>
              </div>
            </div>

            <div className="card rounded-4 mb-4 p-4">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#profile">Profile & Skills</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#work-experience">Work Experience</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#education">Education</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#attached-cv">Attached CV</a>
                </li>
              </ul>

              <div className="mb-4" id="profile">

                <p className='card-text p-4 border mt-4 rounded-2'>I am a Java Developer with 5.7 years of experience in developing web applications using Java technologies. I have a strong knowledge of object-oriented programming, data structures, algorithms, frameworks such as Spring Boot, Hibernate, and Angular & Micro Services.</p>
                <div className='js-skills mt-4'>
                  <h6 className='fs-5 mb-3'>Key Skills</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">SQL Server</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Integration</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Docker</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Restfui Web Services</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Servlets</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Integration</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Data JPA</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Cloud</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">PSQL Server</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">JQuery</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">SCSS</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">HTML5</span>
                  </div>
                </div>


                <div className='js-skills mt-4'>
                  <h6 className='fs-5 mb-3'>Tool Skills</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Restfui Web Services</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Servlets</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Integration</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Data JPA</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">Spring Cloud</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">PSQL Server</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">JQuery</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">SCSS</span>
                    <span class="p-2 mb-3 me-3 card-text px-3 border bd-highlight">HTML5</span>
                  </div>
                </div>


                <div className='js-skills mt-4'>
                  <h6 className='fs-5 mb-3'>Personal Information</h6>
                  <div class="d-flex flex-wrap mb-3">
                    <div class="py-3 mb-3 me-3 card-text px-4 border bd-highlight">
                      <label className='small'>Date of Birth</label>
                      <p className='card-text p-0 m-0'>18 May 1995</p>
                    </div>

                    <div class="py-3 mb-3 me-3 card-text px-4 border bd-highlight">
                      <label className='small'>Gender</label>
                      <p className='card-text p-0 m-0'>Male</p>
                    </div>

                    <div class="py-3 mb-3 me-3 card-text px-4 border bd-highlight">
                      <label className='small'>Martial Status</label>
                      <p className='card-text p-0 m-0'>Married</p>
                    </div>

                    <div class="py-3 mb-3 me-3 card-text px-4 border bd-highlight">
                      <label className='small'>Physically Challenged</label>
                      <p className='card-text p-0 m-0'>No</p>
                    </div>
                  </div>
                </div>



                <div className='js-skills mt-4'>
                  <h6 className='fs-5 mb-3'>Languages known</h6>
                  <div class="d-block">
                    <p className='card-text p-0 m-0 mb-3'>English - Proficient (Read, Write, Speak)</p>
                    <p className='card-text p-0 m-0 mb-3'>French - Proficient (Read, Write)</p>
                    <p className='card-text p-0 m-0'>Spanish - Expert (Read, Write, Speak)</p>
                  </div>
                </div>

              </div>
            </div>

            <div className="card rounded-4 mb-4 p-4 pb-0" id="work-experience">
              <h5 className="card-title">Work Experience</h5>

              <ul class="timeline">
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Sr. Full Stack Developer - Java Developer at Verizon</h6>
                        <p className='card-text pb-3'>Aug '22 till date ( 1y 4m ) <span className='text-primary ms-3 small'>Currently Working</span></p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Java Full Stack Developer - Java Developer - Software Engineer at Techigai</h6>
                        <p className='card-text pb-3'>Feb '20 till Jul '22 ( 2y 5m )</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Software Engineer at Google</h6>
                        <p className='card-text pb-3'>Feb 19 till Jan '22 ( 2y 5m )</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Intern at GetSet Solutions</h6>
                        <p className='card-text pb-3'>Jan '18 till Dec '18 ( 1y 0m )</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

            </div>
            <div className="card rounded-4 mb-4 p-4 pb-0" id="work-experience">
              <div className="card-body">
                <h5 className="card-title">Education</h5>
                <ul class="timeline">
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Bachelor of Architecture</h6>
                        <p className='card-text pb-3'>Carnegie Mellon University, USA</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading">
                        <h6>Intermediate</h6>
                        <p className='card-text pb-3'>Clear Lake Intermediate School, USA</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              </div>
            </div>


            <div className="card rounded-4 mb-4 py-1 px-4" id="attached-cv">
              <div className="d-flex align-items-center justify-content-between">
                <div className="pt-3">
                  <h5>Attached Resume</h5>
                </div>
                <div className="py-4">
                  <p className="card-text">
                    <button className="btn btn-sm btn-md me-3 btn-outline-primary">View Resume</button>
                    <button className="btn btn-sm btn-md btn-primary">Download Resume</button>
                  </p>
                </div>
              </div>
            </div>




          </div>
          <div className='col-xl-4 col-md-12'>
            <div className='js-mn-title flex-grow-1 mt-3'>
              <h4 className='fs-5 pb-2'>Smiliar Profiles</h4>
            </div>
            <RelatedProfile />
          </div>
        </div>

      </div>
    </div>
  );
}

export default JobProfileDetail;