import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PasswordInput from '../PasswordInput/PasswordInput';

const CreatePasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      // Simulate successful password creation
      alert('Password created successfully!');
      navigate('/dashboard'); // Redirect to the dashboard
    } else {
      alert('Passwords do not match!');
    }
  };

  return (
    <div className="create-password-page js-pg-hg">
      <div className="container">
      <div className="row align-items-center justify-content-center">
      <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8 col-11 mt-5 mt-xl-0">
        <div class="card shadow-sm d-flex my-5 mt-xl-0 bx-center border-0 p-4">
              <div class="card-body">
                <form onSubmit={handleSubmit}>
                  <p>Hello Michael,</p>
                  <h4>Set New Password</h4>
                  <label>Password must be at least 8 characters and contain
                    at least one letter and one number.</label>
                  <div className="mt-4">
                    <div className="form-group mb-4">
                      <label for="New Password" className="form-label">New Password</label>
                      <PasswordInput password={password} setPassword={setPassword} />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label for="New Password" className="form-label">Confirm Password</label>
                    <PasswordInput password={confirmPassword} setPassword={setConfirmPassword} />
                  </div>
                  <Link to="/pricing" className="btn btn-md px-5 py-3 mt-2 w-100 btn-primary" type="submit">Login</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePasswordPage;