import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import JobProfileList from '../JobProfiles/JobProfiles';
import { MdBookmarkBorder } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import DropdownReminder from '../DropdownReminder/DropdownReminder';
import { Modal, Button } from 'react-bootstrap';


const SavedProfilePg = () => {


  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [comment, setComment] = useState('');

  const handleClose = () => setShowModal(false);
  const handleShow = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const handleSaveComment = () => {
    alert(`Comment for ${selectedProfile.name}: ${comment}`);
    setShowModal(false);
    setComment('');
  };


  const options = [
    { value: 'option1', label: 'For Call Later' },
    { value: 'option2', label: 'For Interview Follow up' },
    { value: 'option3', label: 'For Sending JD' },
    { value: 'option4', label: 'For Other Task' },
];

const handleSelect = (option) => {
    alert(`Selected: ${option.label}`);
};

  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };


  return (
    <div className="js-pg-hg">
      <div className="container">
        <div className="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="d-lg-flex d-sm-block flex-row align-items-center mb-3 justify-content-between">
                    <div className='js-mn-title me-4 mb-4 mb-lg-0 flex-grow-1'>
                        <h2 className='fw-bold pb-2'>Saved Profiles</h2>
                    </div>

                </div>
          <div class="card rounded-4 mb-4">
  <div class="card-body">
  <div className='d-lg-flex d-sm-block align-content-center justify-content-between mx-2'>
              <div className='py-2 w-25'>
              <div class="form-check align-content-center">
                  <input class="form-check-input mt-3 me-2" type="checkbox" value="" id="flexCheckDefault" />
              <select class="form-select ms-3">
  <option>Bulk actions</option>
  <option>Send JD</option>
  <option>Interview Follow up</option>
  <option>Delete</option>
</select>
                </div>
              </div>
              <div className='py-2'>
              <p className="card-text"><button className='btn fs-4 ms-3 p-0 m-0 icon-clr'><CiMail /></button> <button className='btn fs-4 ms-2 p-0 m-0 icon-clr'><IoMdShare /></button>
              <DropdownReminder options={options} onSelect={handleSelect} />

              </p>

              </div>
            </div>
  </div>
</div>
          <JobProfileList />

          <nav className='my-3 mb-5' aria-label="Page navigation example">
        <ul class="pagination pagination-lg justify-content-center">
          <li class="page-item"><a class="page-link" href="#">Previous</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
          </div>
        </div>
      </div>

      <div>

    </div>
    </div>


  );
};

export default SavedProfilePg;