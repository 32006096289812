// src/Chatbox.jsx
import React, { useState } from 'react';
import './Chatbox.scss'; // Custom styles for the chatbox

const Chatbox = () => {
    const [messages, setMessages] = useState([
        { text: 'Hello! How can I assist you today?', sender: 'bot', username: 'Lawrence', time: new Date(), image: 'https://i.pravatar.cc/60?img=12' },
        { text: 'Can you tell me more about your services?', sender: 'user', username: 'John Doe', time: new Date(), image: 'https://i.pravatar.cc/60?img=10' },
        { text: 'Sure! We offer a variety of services to help you...', sender: 'bot', username: 'Lawrence', time: new Date(), image: 'https://i.pravatar.cc/60?img=12' },
    ]);
    const [input, setInput] = useState('');
    const [isChatboxOpen, setIsChatboxOpen] = useState(true); // State to track chatbox visibility

    const handleSendMessage = () => {
        if (input.trim()) {
            const newMessage = {
                text: input,
                sender: 'user',
                username: 'John Doe', // Static username for simplicity
                time: new Date(),
                image: 'https://i.pravatar.cc/60?img=22', // Static image for simplicity
            };
            setMessages([...messages, newMessage]);
            setInput('');
        }
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        isChatboxOpen && (
            <div className="chatbox-container">
                <div className="card chatbox">
                    <div className="card-header px-4 bg-white d-flex justify-content-between align-items-center">
                        <h4 className='fs-6 text-body p-0 m-0'>Lawrence<br /><span className='small text-black-50'>0001</span></h4>

                        <button
                            className="btn btn-sm rounded-0 py-0 btn-primary"
                            onClick={() => setIsChatboxOpen(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="card-body chatbox-body py-4">
                        <div className="row py-3">
                            {/* User messages column */}
                            <div className="col-12">
                                {messages.filter(msg => msg.sender === 'user').map((msg, index) => (
                                    <div key={index} className="message-container">
                                        <div className="d-flex justify-content-start px-3 mb-5 text-start">
                                            <div className="me-2">{msg.image && <img src={msg.image} alt="user" className="chat-avatar" />}</div>
                                            <div>
                                             <h5 className="fs-6 p-0 m-0 mb-2">{msg.username}  <span className="small ps-2 text-black-50">{formatTime(msg.time)}</span></h5>
                                            <p className="p-3 py-2 rounded-2 bg-white m-0">{msg.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Bot messages column */}
                            <div className="col-12">
                                {messages.filter(msg => msg.sender === 'bot').map((msg, index) => (


<div key={index} className="message-container">
<div className="d-flex justify-content-end px-3 mb-5 text-end">
    <div>
     <h5 className="fs-6 p-0 m-0 mb-2">{msg.username}  <span className="small ps-2 text-black-50">{formatTime(msg.time)}</span></h5>
    <p className="p-3 py-2 rounded-2 bg-primary text-white m-0">{msg.text}</p>
    </div>
    <div className="ms-4">{msg.image && <img src={msg.image} alt="user" className="chat-avatar" />}</div>
</div>
</div>


                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer p-3">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type your message..."
                            />
                            <button className="btn btn-primary" onClick={handleSendMessage}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default Chatbox;