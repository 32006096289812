import React, { useState, useRef, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Messages/Messages.scss';
import Scrollbar from 'react-scrollbar';
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import Chatbox from '../Chatbox/Chatbox';

const jobProfiles = [
    {
      id: 1,
      name: 'Lawrence',
      image: 'https://i.pravatar.cc/150?img=4',
      des: 'Please suggest your availability and Ill share the other details If you are interested then please',
      note:'2'
    },
    {
      id: 2,
      name: 'Vincent',
      image: 'https://i.pravatar.cc/150?img=5',
      des: 'Please suggest your availability and Ill share the other details If you are interested then please',
    },

    {
      id: 3,
      name: 'Douglas',
      image: 'https://i.pravatar.cc/150?img=2',
      des: 'Please suggest your availability and Ill share the other details If you are interested then please',
      note:'4'
    },

    {
      id: 4,
      name: 'Benjamin',
      image: 'https://i.pravatar.cc/150?img=8',
      des: 'Please suggest your availability and Ill share the other details If you are interested then please',
    },
    // Add more profiles as needed
  ];


const Messages = ({ isOpen, onClose }) => {

  const [isChatboxVisible, setIsChatboxVisible] = useState(false);

  const toggleChatbox = () => {
      setIsChatboxVisible(!isChatboxVisible);
  };

    const handleItemClick = () => {
        onClose(); // Close the menu when an item is clicked
    };


    const options = [
      { value: 'option1', label: 'Make a Read' },
      { value: 'option2', label: 'Mute' },
      { value: 'option3', label: 'Block' },
  ];

  const handleSelect = (option) => {
      alert(`Selected: ${option.label}`);
  };


    return (
      <><Menu right isOpen={isOpen} onClose={onClose}>
        <div className='d-flex justify-content- border-bottom py-4 px-3 mb-4 align-items-center vertical-bottom'>
          <div className='p-3 pb-3 pt-4'>
            <h5>Messages</h5>
          </div>
          <div>
            <div className='p-3'>
              <AutocompleteDropdown />
            </div>
          </div>

        </div>
        <Scrollbar
          speed={0.8}
          className="custom-scrollbar"
          horizontal={false}
        >
          <p className='p-0 mx-3'>
            {jobProfiles.map(profile => (

              <div className='d-block p-3 pb-0' key={profile.id}>

                <div className='d-flex justify-content-start px-0 chat-toggle-button cursor-point' onClick={toggleChatbox}>
                  <div className='pe-4'>
                    <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '80px' }} />
                  </div>
                  <div className='d-flex border-bottom pb-4 mb-2 w-100 justify-content-between'>
                    <div>
                      <h5 className="card-title fs-5 mb-1">{profile.name}</h5>
                      <span className="card-text small d-block mb-3">000{profile.id}</span>
                      <p className="card-text text-start d-block small">{profile.des}</p>
                    </div>
                    <div className="card-text small align-drp">
                      <span className='small'>Dec 21</span>
                      <span className='badge rounded-pill bg-primary small m-0 w ms-2 text-white'>{profile.note}</span>
                      <span className='ms-2'>
                        <DropdownMenu options={options} onSelect={handleSelect} />
                      </span>
                    </div>
                  </div>
                </div>

              </div>


            ))}

          </p>

        </Scrollbar>
      </Menu><div>{isChatboxVisible && <Chatbox />}</div></>
    );
};

export default Messages;
