// RelatedProfile.js
import React, { useEffect } from 'react';
import { Tooltip } from 'bootstrap';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { GoLinkExternal } from "react-icons/go";
import { MdModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { BiHide } from "react-icons/bi";

export const jobProfiles = [
  {
    id: 6,
    jobtitle: 'UX/UI Designer',
    company: 'Google',
    image: 'https://brandlogos.net/wp-content/uploads/2015/09/google-favicon-vector-400x400.png',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, USA',
    salary: '$8,6440'
  },
  {
    id: 2,
    jobtitle: 'Devops Engineer',
    company: 'Adobe',
    image: 'https://brandlogos.net/wp-content/uploads/2014/10/adobe-logo-2017-512x512.png',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$6,8440'
  },

  {
    id: 3,
    jobtitle: 'Bench Manager',
    company: 'Infosys',
    image: 'https://brandlogos.net/wp-content/uploads/2022/02/infosys-logo-brandlogos.net_-768x768.png',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant Engineering',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'California, USA',
    salary: '$8,5940'
  },

  {
    id: 5,
    jobtitle: 'Tech Manager',
    company: 'SnapChat',
    image: 'https://brandlogos.net/wp-content/uploads/2015/11/snapchat-logo-brandlogos.net_-512x512.png',
    experience: '7yrs 2m',
    education: 'B.S.C in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: 'Washington, USA',
    salary: '$5,2940'
  },

  {
    id: 4,
    jobtitle: 'Sales Manager',
    company: 'Starbucks',
    image: 'https://brandlogos.net/wp-content/uploads/2011/04/starbucks-logo-vector.png',
    experience: '3yrs 2m',
    education: 'BS in CS, IT, relevant Engineering',
    skills: 'Splunk, Angular, and .NET',
    location: 'New York, USA',
    salary: '$9,1940'
  },

  {
    id: 1,
    jobtitle: 'QA Engineer',
    company: 'Japan Airlines',
    image: 'https://brandlogos.net/wp-content/uploads/2014/09/japan_airlines-logo_brandlogos.net_p51eq.png',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant Engineering',
    skills: 'Docker, Apache POI, Kubernetes',
    location: 'California, USA',
    salary: '$10,1940'
  },

  // Add more profiles as needed
];

function InActivePost() {
  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };


  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
    });
}, []);

  return (
    <div className="row">
      {jobProfiles.map(profile => (
        <div
          className='col-md-6 col-lg-4'
          key={profile.id}

          style={{ cursor: 'pointer' }}
        >
          <div className='card rounded-4 p-2 mb-4'>
            <div className="d-lg-flex js-pst align-items-stretch p-4" key={profile.id}>
              <div className='flex-grow-1'>
                <div className="me-3">
                  <h5 className="card-title"     onClick={() => handleProfileClick(profile.id)}>{profile.jobtitle}</h5>
                  <span className="card-text">{profile.company}</span>
                  <div className='d-flex mt-3'>
                    <div className="card-text me-4">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text me-0">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>

                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '60px' }} />
              </div>


            </div>


            <div className='d-flex align-content-center border-top justify-content-between mx-4 mt-2'>
            <div className='py-3'>
              <button className='btn p-0 m-0 small btn-link text-decoration-none'>Applied 96 <GoLinkExternal /></button>
              </div>
              <div className='py-3 pb-1'>
              <button className='btn p-0 m-0 btn-link me-2 text-decoration-none' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Inactive Post"><BiHide /></button>
              <button className='btn p-0 m-0 btn-link me-2 text-decoration-none' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Edit Post"><MdModeEdit /></button>
              <button className='btn p-0 m-0 btn-link text-decoration-none' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Delete Post"><MdDeleteOutline /></button>
              </div>
            </div>

          </div>
        </div>
      ))}
        <nav className='my-3 mb-4' aria-label="Page navigation example">
        <ul class="pagination pagination-lg justify-content-center">
          <li class="page-item"><a class="page-link" href="#">Previous</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
    </div>

  );
}

export default InActivePost; // Default export
